import React from 'react'
import { Link } from 'gatsby'

import * as styles from './category.module.scss'
import clsx from 'clsx'

interface PostCategoryProps {
  category: string
  query?: string
  type?: `default` | `ghost` | `transparent` | `highlight`
  location?: string
}

export const PostCategory: React.FC<PostCategoryProps> = ({
  category,
  query,
  type,
  location,
}) => {
  return (
    <Link
      className={clsx({
        [styles.postCategory]: true,
        [styles.ghost]: type === `ghost`,
        [styles.transparent]: type === `transparent`,
        [styles.highlight]: type === `highlight`,
      })}
      to={
        query
          ? `${location ? location : '/blog'}?category=${query}`
          : location
            ? location
            : `/blog/`
      }
      itemProp="category"
    >
      {category}
    </Link>
  )
}

PostCategory.defaultProps = {
  type: `default`,
}
