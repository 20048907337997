import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import * as styles from './post.module.scss'

import { PostCategory } from './category'

interface PostProps {
  size?: `large` | `normal`
  title: string
  description: string
  url: string
  date: string
  thumbnail: string
  category: string
}

export const Post: React.FC<PostProps> = ({
  size,
  title,
  description,
  url,
  date,
  thumbnail,
  category,
}) => {
  if (size === `large`) {
    return (
      <Link to={url}>
        <article
          className={styles.largePost}
          itemScope={true}
          itemType="http://schema.org/Article"
        >
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src={thumbnail} alt={title} />
            </div>
            <div className="col-lg-6">
              <PostCategory
                category={category}
                query={category}
                type="highlight"
              />
              <h2 title={title} itemProp="title">
                {title}
              </h2>
              <p
                title={description}
                dangerouslySetInnerHTML={{ __html: description }}
                itemProp="description"
              />
              <span itemProp="url">
                <span className="mr-2">Leia mais</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </div>
          </div>
        </article>
      </Link>
    )
  }

  return (
    <Link to={url}>
      <article
        className={styles.normalPost}
        itemScope={true}
        itemType="http://schema.org/Article"
      >
        <div
          className={styles.background}
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
        <div className={styles.overlay}></div>
        <div className={styles.content}>
          <PostCategory
            category={category}
            query={category}
            type="transparent"
          />
          <h2 title={title} itemProp="title">
            {title}
          </h2>
          <p
            title={description}
            dangerouslySetInnerHTML={{ __html: description }}
            itemProp="description"
          />
          <span itemProp="url">
            <span className="mr-2">Leia mais</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </div>
      </article>
    </Link>
  )
}

Post.defaultProps = {
  size: `normal`,
}
