import React from 'react'
import clsx from 'clsx'

import * as styles from './heading.module.scss'
import banner from '../../images/homeBanner.png'

interface HeadingProps {
  message?: string
  title?: string
  backgroundUrl?: string
  addOverlay?: boolean
  isHome?: boolean
}

export const Heading: React.FC<HeadingProps> = ({
  message,
  title,
  backgroundUrl,
  addOverlay,
  isHome,
}) => {
  return (
    <div
      className={clsx({
        [styles.heading]: true,
        [styles.addOverlay]: addOverlay,
        [styles.isHome]: isHome,
      })}
      style={{ backgroundImage: `url(${backgroundUrl})` }}
    >
      <p>{message}</p>
      <h1>{title}</h1>
    </div>
  )
}

Heading.defaultProps = {
  backgroundUrl: banner,
  addOverlay: false,
  isHome: false,
}
