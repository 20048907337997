export const getElementOffset = (elementOrSelector: HTMLElement | string) => {

  let element: HTMLElement
  if (typeof elementOrSelector === `string`) {
    element = window.document.querySelector(elementOrSelector)!
  } else {
    element = elementOrSelector
  }

  const rect = element.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft
  }
}