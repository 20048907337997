import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { PageProps, graphql, Link, navigate } from 'gatsby'

import * as styles from './blogList.module.scss'

import { SectionTitle } from '../components/sectionTitle'
import { Layout } from '../components/layout'
import { Heading } from '../components/heading'
import { Post, PostCategory } from '../components/post'
import { getElementOffset } from '../utils/getElementOffset'
import { SEO } from '../components/seo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'

interface Post {
  excerpt: string
  fields: {
    slug: string
  }
  frontmatter: {
    date: string
    title: string
    description: string
    highlight: boolean
    thumbnail: string
    category: string
  }
}

interface BlogPageProps extends PageProps {
  data: {
    site: any
    allMarkdownRemark: {
      nodes: Post[]
    }
    markdownRemark: {
      fields: {
        slug: string
      }
      frontmatter: {
        date: string
        title: string
        description: string
        highlight: boolean
        thumbnail: string
        category: string
      }
    }
  }
}

const postsPerPage = 9

const categories = [
  'BPM',
  'Controladoria',
  'SGI',
  'RPA',
  'Compliance',
  'OKR',
  'Gestão de Projetos',
]

const BlogList: React.FC<BlogPageProps> = ({ data, location, pageContext }) => {
  const [category, setCategory] = useState<string>()
  const [page, setPage] = useState<number>(1)
  const posts = data.allMarkdownRemark.nodes
  const highlightPost = data.markdownRemark
  const minIndex = (page - 1) * postsPerPage
  const maxIndex = minIndex + postsPerPage - 1
  const pages = Math.ceil(posts.length / postsPerPage)

  const filteredPosts = posts.filter((post, index) => {
    const matchesPage = index >= minIndex && index <= maxIndex
    const matchesCategory = category === post.frontmatter.category
    if (category) return matchesCategory
    return matchesPage
  })

  const scrollToPosts = () => {
    const { top } = getElementOffset(`#all-posts`)
    window.scrollTo({ top: top - 50, behavior: 'smooth' })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const _category = queryParams.get(`category`)
    const _page = parseInt(queryParams.get(`page`) || ``) || 1
    if (_category) {
      setCategory(_category)
      scrollToPosts()
    } else {
      setCategory(undefined)
    }
    if (_page) {
      setPage(_page)
      scrollToPosts()
    } else {
      setPage(1)
    }
  }, [location])

  if (posts.length === 0) {
    return (
      <Layout location={location}>
        <SEO title="Blog" />

        <Heading isHome />

        <div className={styles.empty}>
          <SectionTitle
            title="Está vazio por aqui"
            description="Nenhum post foi publicado ainda 😕"
          />
        </div>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <SEO title="Blog" />

      <Heading isHome />

      <div className={styles.highlight}>
        <h2 className={styles.highlightTitle}>Em destaque</h2>

        {highlightPost && (
          <>
            <section id="highlights">
              <Post
                key={highlightPost.fields.slug}
                size="large"
                title={highlightPost.frontmatter.title}
                description={highlightPost.frontmatter.description}
                date={highlightPost.frontmatter.date}
                url={highlightPost.fields.slug}
                thumbnail={highlightPost.frontmatter.thumbnail}
                category={highlightPost.frontmatter.category}
              />
            </section>
          </>
        )}
      </div>

      <div className={clsx([`container`, styles.blogList])}>
        <section id="all-posts">
          <SectionTitle title="Todas as postagens" />

          <div className="d-flex flex-wrap justify-content-center mb-5">
            <PostCategory
              category="Todas"
              query={undefined}
              type={!category ? `default` : `ghost`}
              location={location.pathname}
            />

            {categories.map((c) => (
              <PostCategory
                key={c}
                category={c}
                query={c}
                type={c === category ? `default` : `ghost`}
                location={location.pathname}
              />
            ))}
          </div>

          <div className="row">
            {filteredPosts.length === 0 ? (
              <div className="col d-flex justify-content-center my-5">
                <h3 className="d-inline">
                  Nenhum post nessa categoria nesta pagina 😕
                </h3>
              </div>
            ) : (
              filteredPosts.map((p) => (
                <div key={p.fields.slug} className="col-lg-4">
                  <Post
                    title={p.frontmatter.title}
                    description={p.frontmatter.description || p.excerpt}
                    date={p.frontmatter.date}
                    url={p.fields.slug}
                    thumbnail={p.frontmatter.thumbnail}
                    category={p.frontmatter.category}
                  />
                </div>
              ))
            )}
          </div>
        </section>
      </div>

      <div className="row" hidden={!!category}>
        <div className="col-12">
          <ul className={styles.pagination}>
            <li className={clsx({ [styles.disabled]: page === 1 })}>
              <Link to={`/blog/?page=${page - 1}`}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Link>
            </li>
            {Array.from({ length: pages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
                className={clsx({ [styles.active]: i + 1 === page })}
              >
                <Link to={`/blog/?page=${i + 1}`}>{i + 1}</Link>
              </li>
            ))}
            <li className={clsx({ [styles.disabled]: page - 1 === pages })}>

              <Link to={`/blog/?page=${page + 1}`}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default BlogList

export const pageQuery = graphql`
  query pageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-BR")
          title
          description
          highlight
          thumbnail
          category
        }
      }
    }
    markdownRemark(frontmatter: { highlight: { eq: true } }) {
      frontmatter {
        date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-BR")
        title
        description
        highlight
        thumbnail
        category
      }
      fields {
        slug
      }
    }
  }
`
