import clsx from 'clsx'
import React from 'react'

import favicon from '../../images/linhas.svg'
import * as styles from './sectionTitle.module.scss'

interface SectionTitleProps {
  className?: string
  title: string
  description?: string
}

export const SectionTitle: React.FC<SectionTitleProps> = (props) => {
  return (
    <div
      className={clsx([
        `py-5 d-flex flex-column align-items-md-center`,
        styles.sectionTitle,
        props.className,
      ])}
    >
      <img src={favicon} alt="Valore Brasil" />
      <h1>{props.title}</h1>
      {props.description && <p>{props.description}</p>}
    </div>
  )
}
